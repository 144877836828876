@use "fonts";

* {
  margin:0;
  padding:0;
}
html { height: 100% }

body {
  height: 100%;
  background-color:#ccc;
}

h1, h2 {
  margin: 15px;
  text-align: center;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  padding:25px;
}

p {
  text-align:center;
  margin-bottom: 5px;
}

table {
  max-width: 600px;
  width:100%;
  margin: 0 auto
}
caption {
  font-size:24px
}

.errorpage {
  background-color:#eee;
  font-family: "Calibri", sans-serif;
  border-radius:10px;
  padding:15px;
  max-width:640px;
  min-width: 240px;
  margin:auto;

  .errorpage-image {
    display: flex;
    justify-content: center;
  }
}

.refcode {
  font-size: 25px;
  margin-top: 15px;
  text-align: center;
}

.refcode-explanation {
  font-size: 10px;
}